<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col>
                <v-row>
                    <v-col cols="8">
                        <div class="text-h5 font-weight-bold">
                            Best NFT to be minted
                        </div>
                    </v-col>

                    <v-col>
                        <v-dialog
                            v-if="!mintNft"
                            v-model="mintDialog"
                            scrollable
                            max-width="300px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="secondary"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    + add
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>Select NFT mint</v-card-title>
                                <v-divider></v-divider>
                                <v-card-text style="height: 300px">
                                    <v-radio-group
                                        v-model="mintNftSelected"
                                        column
                                    >
                                        <v-radio
                                            color="secondary"
                                            v-for="(item, i) in mintNftokens"
                                            :key="i"
                                            :label="item.title"
                                            :value="item.id"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="mintDialog = false"
                                    >
                                        Close
                                    </v-btn>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="addMint"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                    <v-col v-if="mintNft">
                        <v-btn color="error" @click="removeMint"
                            >- remove</v-btn
                        >
                    </v-col>
                </v-row>

                <v-row class="pa-2">
                    <ProductCard
                        class="my-2"
                        v-if="mintNft"
                        :product="mintNft"
                    />
                </v-row>
            </v-col>

            <v-col>
                <v-row>
                    <v-col cols="8">
                        <div class="text-h5 font-weight-bold">
                            Top NFT to buy
                        </div></v-col
                    >

                    <v-col>
                        <v-dialog
                            v-if="!liveNft"
                            v-model="liveDialog"
                            scrollable
                            max-width="300px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="secondary"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    + add
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>Select NFT live</v-card-title>
                                <v-divider></v-divider>
                                <v-card-text style="height: 300px">
                                    <v-radio-group
                                        color="secondary"
                                        v-model="liveNftSelected"
                                        column
                                    >
                                        <v-radio
                                            color="secondary"
                                            v-for="(item, i) in liveNftokens"
                                            :key="i"
                                            :label="item.title"
                                            :value="item.id"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="liveDialog = false"
                                    >
                                        Close
                                    </v-btn>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="addLive"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                    <v-col v-if="liveNft">
                        <v-btn color="error" @click="removeLive">
                            - remove
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row class="pa-2">
                    <ProductCard
                        class="my-2"
                        v-if="liveNft"
                        :product="liveNft"
                    />
                </v-row>
            </v-col>
        </v-row>
        <v-row class="mt-15">
            <v-col cols="12" md="6" sm="12">
                <v-row>
                    <v-col cols="8">
                        <div class="text-h5 font-weight-bold">
                            Top position mint
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2">
                        <v-dialog
                            v-model="mintPaidDialog"
                            scrollable
                            max-width="300px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="secondary"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    + add
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>Select NFT mint</v-card-title>
                                <v-divider></v-divider>
                                <v-card-text style="height: 300px">
                                    <v-radio-group
                                        color="secondary"
                                        v-model="mintPaidNftSelected"
                                        column
                                    >
                                        <v-radio
                                            color="secondary"
                                            v-for="(
                                                item, i
                                            ) in mintFreeNfTokens"
                                            :key="i"
                                            :label="item.title"
                                            :value="item.id"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="mintPaidDialog = false"
                                    >
                                        Close
                                    </v-btn>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="addPaidMint"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12">
                        <CustomTable
                            :headers="headers"
                            :items="mintPaidNftokens"
                            @remove="removePaidMint"
                            @up="upPaidMint"
                            @down="downPaidMint"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6" sm="12">
                <v-row>
                    <v-col cols="8">
                        <div class="text-h5 font-weight-bold">
                            Top position live
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2">
                        <v-dialog
                            v-model="livePaidDialog"
                            scrollable
                            max-width="300px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="secondary"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    + add
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>Select NFT live</v-card-title>
                                <v-divider></v-divider>
                                <v-card-text style="height: 300px">
                                    <v-radio-group
                                        color="secondary"
                                        v-model="livePaidNftSelected"
                                        column
                                    >
                                        <v-radio
                                            color="secondary"
                                            v-for="(
                                                item, i
                                            ) in liveFreeNfTokens"
                                            :key="i"
                                            :label="item.title"
                                            :value="item.id"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="livePaidDialog = false"
                                    >
                                        Close
                                    </v-btn>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="addPaidLive"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12">
                        <CustomTable
                            :headers="headers"
                            :items="livePaidNftokens"
                            @remove="removePaidLive"
                            @up="upPaidLive"
                            @down="downPaidLive"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </admin-dashboard-layout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "TopNft",
    components: {
        AdminDashboardLayout: () => import("@/layouts/AdminDashboardLayout"),
        ProductCard: () => import("@/components/ProductCard.vue"),
    },
    data() {
        return {
            mintDialog: false,
            liveDialog: false,
            livePaidDialog: false,
            mintPaidDialog: false,
            mintNftSelected: null,
            liveNftSelected: null,
            livePaidNftSelected: null,
            mintPaidNftSelected: null,
        };
    },
    computed: {
        ...mapGetters({
            nftoken: "nftoken/item",
            nftokens: "nftoken/allItems",
        }),
        liveNft() {
            return this.nftokens.find(
                (item) => item.order == 1 && item.type == "live"
            );
        },
        mintNft() {
            return this.nftokens.find(
                (item) => item.order == 1 && item.type == "mint"
            );
        },
        livePaidNftokens() {
            return this.sortByOrder(
                this.nftokens.filter(
                    (item) =>
                        item.is_free == false &&
                        item.order > 1 &&
                        item.type == "live"
                )
            );
        },
        mintPaidNftokens() {
            return this.sortByOrder(
                this.nftokens.filter(
                    (item) =>
                        item.is_free == false &&
                        item.order > 1 &&
                        item.type == "mint"
                )
            );
        },
        liveFreeNfTokens() {
            return this.nftokens.filter(
                (item) => item.is_free && item.order != 1 && item.type == "live"
            );
        },
        mintFreeNfTokens() {
            return this.nftokens.filter(
                (item) => item.is_free && item.order != 1 && item.type == "mint"
            );
        },
        mintNftokens() {
            return this.nftokens.filter(
                (item) => item.order == null && item.type == "mint"
            );
        },
        liveNftokens() {
            return this.nftokens.filter(
                (item) => item.order == null && item.type == "live"
            );
        },
    },
    methods: {
        ...mapMutations({
            setToken: "nftoken/setItem",
        }),
        ...mapActions({
            setTokens: "nftoken/setAllItems",
            updateToken: "nftoken/updateItem",
            updateOrder: "nftoken/updateOrder",
        }),
        async addMint() {
            if (this.mintNftSelected) {
                let nftoken = this.mintNftokens.find(
                    (item) => item.id == this.mintNftSelected
                );
                nftoken.order = 1;
                this.setToken(nftoken);
                await this.updateToken();
                this.setTokens({ none_pagination: true });
                this.mintDialog = false;
            }
        },
        async addLive() {
            if (this.liveNftSelected) {
                let nftoken = this.liveNftokens.find(
                    (item) => item.id == this.liveNftSelected
                );
                nftoken.order = 1;
                this.setToken(nftoken);
                await this.updateToken();
                this.setTokens({ none_pagination: true });
                this.liveDialog = false;
            }
        },
        async removeMint() {
            if (this.mintNft) {
                this.setToken(this.mintNft);
                this.nftoken.order = null;
                await this.updateToken();
                this.setTokens({ none_pagination: true });
            }
        },
        async removeLive() {
            if (this.liveNft) {
                this.setToken(this.liveNft);
                this.nftoken.order = null;
                await this.updateToken();
                this.setTokens({ none_pagination: true });
            }
        },
        async upPaidLive(index) {
            if (index > 0) {
                await this.updateOrder({
                    prev: this.livePaidNftokens[index - 1].id,
                    next: this.livePaidNftokens[index].id,
                });
                this.setTokens({ none_pagination: true });
            }
        },
        async upPaidMint(index) {
            if (index > 0) {
                await this.updateOrder({
                    prev: this.mintPaidNftokens[index - 1].id,
                    next: this.mintPaidNftokens[index].id,
                });
                this.setTokens({ none_pagination: true });
            }
        },
        async downPaidLive(index) {
            if (index < this.livePaidNftokens.length - 1) {
                await this.updateOrder({
                    prev: this.livePaidNftokens[index].id,
                    next: this.livePaidNftokens[index + 1].id,
                });
                this.setTokens({ none_pagination: true });
            }
        },
        async downPaidMint(index) {
            if (index < this.mintPaidNftokens.length - 1) {
                await this.updateOrder({
                    prev: this.mintPaidNftokens[index].id,
                    next: this.mintPaidNftokens[index + 1].id,
                });
                this.setTokens({ none_pagination: true });
            }
        },
        async addPaidLive() {
            if (this.livePaidNftokens) {
                let nftoken = this.nftokens.find(
                    (item) => item.id == this.livePaidNftSelected
                );
                nftoken.is_free = false;
                let nftokenOrder = this.livePaidNftokens.length + 2;
                nftoken.order =
                    this.livePaidNftokens.length == 0
                        ? nftokenOrder
                        : this.livePaidNftokens[
                              this.livePaidNftokens.length - 1
                          ].order + 1;
                this.setToken(nftoken);
                await this.updateToken({ none_pagination: true });
                this.livePaidDialog = false;
            }
        },
        async addPaidMint() {
            if (this.mintPaidNftSelected) {
                let nftoken = this.nftokens.find(
                    (item) => item.id == this.mintPaidNftSelected
                );
                nftoken.is_free = false;
                let nftokenOrder = this.mintPaidNftokens.length + 2;
                nftoken.order =
                    this.mintPaidNftokens.length == 0
                        ? nftokenOrder
                        : this.mintPaidNftokens[
                              this.mintPaidNftokens.length - 1
                          ].order + 1;
                this.setToken(nftoken);
                await this.updateToken({ none_pagination: true });
                this.mintPaidDialog = false;
            }
        },
        async removePaidLive(index) {
            let nftoken = this.livePaidNftokens[index];
            if (nftoken) {
                nftoken.is_free = true;
                nftoken.order = null;
                this.setToken(nftoken);
                await this.updateToken({ none_pagination: true });
            }
        },
        async removePaidMint(index) {
            let nftoken = this.mintPaidNftokens[index];
            if (nftoken) {
                nftoken.is_free = true;
                nftoken.order = null;
                this.setToken(nftoken);
                await this.updateToken({ none_pagination: true });
            }
        },
        sortByOrder(nftokens) {
            return nftokens.sort((a, b) => {
                if (a.order < b.order) {
                    return -1;
                }

                if (a.order > b.order) {
                    return 1;
                }

                return 0;
            });
        },
    },
    created() {
        this.$store.commit("nftoken/setAllItems", []);
        this.setTokens({ none_pagination: true });
        this.headers = [
            { text: "Type", value: "type" },
            { text: "Title", value: "title" },
            { text: "Status", value: "status" },
        ];
    },
};
</script>

<style lang="scss" scoped>
</style>
