var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin-dashboard-layout',[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" Best NFT to be minted ")])]),_c('v-col',[(!_vm.mintNft)?_c('v-dialog',{attrs:{"scrollable":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_vm._v(" + add ")])]}}],null,false,2203498036),model:{value:(_vm.mintDialog),callback:function ($$v) {_vm.mintDialog=$$v},expression:"mintDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Select NFT mint")]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"300px"}},[_c('v-radio-group',{attrs:{"column":""},model:{value:(_vm.mintNftSelected),callback:function ($$v) {_vm.mintNftSelected=$$v},expression:"mintNftSelected"}},_vm._l((_vm.mintNftokens),function(item,i){return _c('v-radio',{key:i,attrs:{"color":"secondary","label":item.title,"value":item.id}})}),1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.mintDialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.addMint}},[_vm._v(" Save ")])],1)],1)],1):_vm._e()],1),(_vm.mintNft)?_c('v-col',[_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.removeMint}},[_vm._v("- remove")])],1):_vm._e()],1),_c('v-row',{staticClass:"pa-2"},[(_vm.mintNft)?_c('ProductCard',{staticClass:"my-2",attrs:{"product":_vm.mintNft}}):_vm._e()],1)],1),_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" Top NFT to buy ")])]),_c('v-col',[(!_vm.liveNft)?_c('v-dialog',{attrs:{"scrollable":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_vm._v(" + add ")])]}}],null,false,2203498036),model:{value:(_vm.liveDialog),callback:function ($$v) {_vm.liveDialog=$$v},expression:"liveDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Select NFT live")]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"300px"}},[_c('v-radio-group',{attrs:{"color":"secondary","column":""},model:{value:(_vm.liveNftSelected),callback:function ($$v) {_vm.liveNftSelected=$$v},expression:"liveNftSelected"}},_vm._l((_vm.liveNftokens),function(item,i){return _c('v-radio',{key:i,attrs:{"color":"secondary","label":item.title,"value":item.id}})}),1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.liveDialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.addLive}},[_vm._v(" Save ")])],1)],1)],1):_vm._e()],1),(_vm.liveNft)?_c('v-col',[_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.removeLive}},[_vm._v(" - remove ")])],1):_vm._e()],1),_c('v-row',{staticClass:"pa-2"},[(_vm.liveNft)?_c('ProductCard',{staticClass:"my-2",attrs:{"product":_vm.liveNft}}):_vm._e()],1)],1)],1),_c('v-row',{staticClass:"mt-15"},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" Top position mint ")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_vm._v(" + add ")])]}}]),model:{value:(_vm.mintPaidDialog),callback:function ($$v) {_vm.mintPaidDialog=$$v},expression:"mintPaidDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Select NFT mint")]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"300px"}},[_c('v-radio-group',{attrs:{"color":"secondary","column":""},model:{value:(_vm.mintPaidNftSelected),callback:function ($$v) {_vm.mintPaidNftSelected=$$v},expression:"mintPaidNftSelected"}},_vm._l((_vm.mintFreeNfTokens),function(item,i){return _c('v-radio',{key:i,attrs:{"color":"secondary","label":item.title,"value":item.id}})}),1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.mintPaidDialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.addPaidMint}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('CustomTable',{attrs:{"headers":_vm.headers,"items":_vm.mintPaidNftokens},on:{"remove":_vm.removePaidMint,"up":_vm.upPaidMint,"down":_vm.downPaidMint}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" Top position live ")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_vm._v(" + add ")])]}}]),model:{value:(_vm.livePaidDialog),callback:function ($$v) {_vm.livePaidDialog=$$v},expression:"livePaidDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Select NFT live")]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"300px"}},[_c('v-radio-group',{attrs:{"color":"secondary","column":""},model:{value:(_vm.livePaidNftSelected),callback:function ($$v) {_vm.livePaidNftSelected=$$v},expression:"livePaidNftSelected"}},_vm._l((_vm.liveFreeNfTokens),function(item,i){return _c('v-radio',{key:i,attrs:{"color":"secondary","label":item.title,"value":item.id}})}),1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.livePaidDialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.addPaidLive}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('CustomTable',{attrs:{"headers":_vm.headers,"items":_vm.livePaidNftokens},on:{"remove":_vm.removePaidLive,"up":_vm.upPaidLive,"down":_vm.downPaidLive}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }